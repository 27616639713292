import { FC, useMemo, useState } from "react";
import {
  ArrowTopRightIcon,
  Button,
  EnvelopeClosedIcon,
  Heading,
  Text,
  toolkitCx,
} from "@sundae/ui-toolkit";
import YouTube from "react-youtube";
import { MouseParallax } from "react-just-parallax";
import cx from "classnames";
import { useResponsive } from "@sundae/react-hooks";
import { EEX_PATHS } from "../../constants/paths";

const Banner: FC = () => {
  const [ytReady, setYtReady] = useState(false);
  const responsive = useResponsive();
  const isLargeScreen = useMemo(
    () => responsive.lg || responsive.xl || responsive.xxl,
    [responsive]
  );

  return (
    <div className="flex w-full flex-col items-center justify-between gap-12 lg:flex-row">
      <div className="flex w-full flex-col gap-4 lg:w-1/2">
        <Heading tag="h1" size={isLargeScreen ? "7xl" : "4xl"} weight="black">
          Governance
          {isLargeScreen && <br />} for{" "}
          <Text gradient tag="span" size={isLargeScreen ? "7xl" : "4xl"} weight="black">
            Everyone
          </Text>
          .
        </Heading>

        <Text className="max-w-[635px]" tag="p" size={isLargeScreen ? "lg" : "base"}>
          SundaeSwap Governance is a layer 2 governance solution for projects building on Cardano.
          We are currently offering this platform as a service.
        </Text>

        <div className="flex flex-col items-center gap-4 md:flex-row">
          <Button
            className="group flex w-full items-center gap-2 md:w-auto"
            variant="secondary"
            onClick={() => window.open(EEX_PATHS.ABOUT)}
          >
            Learn More
            <ArrowTopRightIcon
              className={cx(
                "group-hover:translate-x-0.5 group-hover:-translate-y-0.5",
                toolkitCx.layout.transition.transform
              )}
            />
          </Button>
          <Button
            className="group flex w-full items-center gap-2 md:w-auto"
            onClick={() =>
              window.open(
                "mailto:contact@sundaeswap.finance?subject=SundaeSwap Governance&body=Hi SundaeSwap Team!%0D%0A%0D%0AWe are interested in your Governance Solution and would like to learn more about it.",
                "_self"
              )
            }
            variant="colored"
          >
            Contact us
            <EnvelopeClosedIcon
              className={cx(
                "group-hover:translate-x-0.5 group-hover:-translate-y-0.5",
                toolkitCx.layout.transition.transform
              )}
            />
          </Button>
        </div>
      </div>

      <div className="relative w-full lg:w-1/2">
        <MouseParallax strength={0.02}>
          <div
            className={cx("opacity-0 transition-opacity", {
              "opacity-100": ytReady,
            })}
          >
            <YouTube
              className="relative mb-[50px] h-0 w-full overflow-hidden rounded-lg pb-[56.25%] drop-shadow-yt"
              iframeClassName="w-full h-full absolute top-0 left-0"
              videoId="vJdLO4RXZ4s"
              onReady={() => setYtReady(true)}
            />
          </div>
        </MouseParallax>
      </div>
    </div>
  );
};

export default Banner;
