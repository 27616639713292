import { Loader } from "@sundae/ui-toolkit";
import { FC } from "react";
import { ILoaderProps } from "@sundae/ui-toolkit/dist/types/components/atoms/Loader/Loader";

const LoadingState: FC<Pick<ILoaderProps, "size">> = ({ size = "medium" }) => (
  <div className="flex h-full items-center justify-center p-8">
    <Loader size={size} />
  </div>
);

export default LoadingState;
