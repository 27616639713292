import { FC, ReactNode } from "react";
import { HelmetProvider } from "react-helmet-async";

export type TProvidersProps = {
  children: ReactNode;
};

export const Providers: FC<TProvidersProps> = ({ children }) => {
  return <HelmetProvider>{children}</HelmetProvider>;
};

export default Providers;
