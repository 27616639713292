import { ExternalLink } from "@sundae/ui-toolkit";
import cx from "classnames";
import { FC, PropsWithChildren } from "react";

import { EEX_PATHS } from "../../constants/paths";

const Layout: FC<PropsWithChildren<{}>> = ({ children }): JSX.Element => {
  return (
    <>
      <main className="relative h-full min-h-screen overflow-hidden">
        <section
          className={cx(
            "mt-24 mb-10 w-full max-w-screen-xl px-6",
            "md:mb-0",
            "lg:absolute lg:top-1/2 lg:left-1/2 lg:mt-0 lg:-translate-y-1/2 lg:-translate-x-1/2"
          )}
        >
          {children}
        </section>
      </main>

      <footer
        className={cx(
          "mx-auto mb-4 max-w-screen-xl px-8 text-center",
          "md:fixed md:bottom-0 md:left-1/2 md:mb-0 md:h-12 md:w-full md:-translate-x-1/2"
        )}
      >
        <ExternalLink
          arrow="top-right"
          href={EEX_PATHS.LEGAL}
          target="_blank"
          rel="noreferrer noopener"
          variant="muted"
        >
          Legal
        </ExternalLink>
      </footer>

      <div className="fixed top-0 left-0 z-[-2] h-full w-full bg-gradient-to-t from-white to-gray-300 dark:from-gray-600 dark:via-gray-700 dark:to-gray-800" />
    </>
  );
};

export default Layout;
