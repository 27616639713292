import { useTheme } from "@sundae/react-hooks";
import { Header as UIHeader, QuestionMarkCircledIcon, Tooltip } from "@sundae/ui-toolkit";
import { IHeaderNavigationMap } from "@sundae/ui-toolkit/dist/types/components/organisms/Header/Header";
import { lazy, useMemo } from "react";

import { EEX_PATHS, EPATHS } from "../../constants/paths";

const Header = (): JSX.Element => {
  const { theme, toggleTheme } = useTheme();

  const navItems: IHeaderNavigationMap[] = useMemo(
    () => [
      {
        to: EEX_PATHS.WEBSITE,
        title: "About SundaeSwap",
        external: true,
      },
      {
        dropdown: {
          menuTitle: "Follow Us",
          menuContent: [
            {
              to: EEX_PATHS.TWITTER,
              title: "Twitter",
              description: "@SundaeSwap",
              external: true,
            },
            {
              to: EEX_PATHS.DISCORD,
              title: "Discord",
              description: "Join our SundaeSwap Community.",
              external: true,
            },
            {
              to: EEX_PATHS.MEDIUM,
              title: "Medium",
              description: "Follow our Medium for the latest updates.",
              external: true,
            },
          ],
        },
      },
    ],
    []
  );

  return (
    <UIHeader
      navigationItems={navItems}
      homepageLinkHref={EPATHS.HOME}
      homepageLinkTitle="Home | SundaeSwap Governance"
      isConnected={false}
      showThemeToggle
      renderTertiaryNav={({ HeaderButton }) => (
        <Tooltip
          trigger={
            <HeaderButton onClick={() => window.open(EEX_PATHS.ABOUT, "_blank")}>
              <QuestionMarkCircledIcon />
            </HeaderButton>
          }
          tooltipLabel="Learn more about our Governance Solution"
        />
      )}
      theme={theme}
      toggleTheme={toggleTheme}
      transparent
    />
  );
};

export default Header;
