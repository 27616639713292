import { Outlet, ReactLocation, Route, Router } from "@tanstack/react-location";
import { FC, Suspense } from "react";
import Header from "../components/Header";

import Layout from "../components/Layout";
import LoadingState from "../components/LoadingState";
import { EPATHS } from "../constants/paths";

import Home from "./Home";

interface IRoutesProps {
  location: ReactLocation;
}

const AppRoutes: Route[] = [
  {
    id: "home",
    path: EPATHS.HOME,
    element: <Home />,
    loader: () => {
      return {
        align: "center",
      };
    },
  },
];

export const Routes: FC<IRoutesProps> = ({ location }): JSX.Element => {
  return (
    <Router useErrorBoundary location={location} routes={AppRoutes}>
      <Header />
      <Layout>
        <Suspense fallback={<LoadingState />}>
          <Outlet />
        </Suspense>
      </Layout>
    </Router>
  );
};
